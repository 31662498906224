@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');

@import 'root';
@import 'common';
@import 'form';
@import 'login';
@import 'aside';
@import 'component/pagination';
@import 'component/tabs';
@import 'component/popup';
@import 'navigation';
@import 'custom';
@import 'select-2';
@import 'rsuite';
@import 'cmspage';
@import 'agile-board';
@import 'component/table-freeze';
@import 'component/global-loading';
@import 'order-consignment';
@import 'component/category-table';

.w-38px {
  width: 38px !important;
}

.w-90{
  width: 90% !important;
}

.h-38px {
  height: 38px !important;
}

.ad-image-cover{
  object-fit: cover;
}

.rmsc {
  .dropdown-container {
    border-radius: 6px !important;
    border-color: #e4e6ef !important;
  }
}

// added comment

.rmsc .dropdown-heading {
  height: 34px !important;
}

.dropdown-heading-dropdown-arrow {
  width: 22px;
  height: 22px;
}

.dropdown-heading-value {
  .gray {
    color: var(--kt-form-select-color);
    font-size: 14px;
  }
}

.form-check-input-smallbg {
  background-size: 14px auto;
}

.tag-picker-wrapper {
  .form-control {
    padding: 0;
  }
}

// sold product card height

.sold-product-bottom-card {
  @media only screen and (min-width: 1201px) {
    height: calc(50% - 20px);
    margin-top: 20px;
  }

  @media only screen and (max-width: 1200px) {
    margin-top: 20px;
  }
}

.sold-product-view-cards {
  @media only screen and (max-width: 1399px) {
    margin-bottom: 20px;
  }

  .h-50 {
    @media only screen and (max-width: 1200px) {
      height: auto !important;
    }
  }
}

.sold-product-round-chart-section {
  @media only screen and (max-width: 767px) {
    margin-top: 20px !important;
  }
}

.sold-product-fix-table {
  thead {
    position: sticky;
  }
}

// admin app redesign

.express-order-checkbox {
  &.form-check-custom.form-check-solid .form-check-input {
    border: var(--kt-form-check-input-border);
  }
}

.jodit-workplace,
.jodit-wysiwyg {
  min-height: 455px !important;
}

.product-create-wrapper {

  .jodit-workplace,
  .jodit-wysiwyg {
    min-height: 300px !important;
  }
}

.jodit-toolbar__box {
  background-color: #f5f8fa !important;
}

.input-date-picker {
  z-index: 1055;
}

.project-table-name-col {
  .rating-label {
    opacity: 0;

    &.checked {
      opacity: 1;
    }
  }

  &:hover {
    .rating-label {
      opacity: 1;
    }
  }
}

.ad-dropzone {
  border: 1px dashed #bfbfbf;
  background: #ffffff;
  padding: 16px 16px 16px 32px;
  text-align: left;

  .download-icon {
    i {
      font-size: 40px;
    }
  }
}

.box-shadow-none {
  box-shadow: none !important;
}

.mw-90 {
  max-width: 90% !important;
}

.price-list-edit-mode {
  // max-height: 450px;
  max-height: 60vh;
}

.actionTaken-input {
  display: block;
  line-height: 20px;
  white-space: nowrap;
  border-radius: 6px;
}

// metronic drawer componetn
.app-drawer-nav-body {
  height: calc(100% - 160px);
}

.app-drawer-nav-section {
  display: none !important;

  &.drawer-on {
    display: block !important;
  }
}

// lexical editor
.lexical-editor {

  [type='checkbox']:checked,
  [type='checkbox']:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  [type='checkbox']:checked+label,
  [type='checkbox']:not(:checked)+label {
    position: relative;
    padding-right: 55px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
  }

  [type='checkbox']:checked+label:before,
  [type='checkbox']:not(:checked)+label:before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #666;
    background: #fff;
  }

  [type='checkbox']:checked+label:after,
  [type='checkbox']:not(:checked)+label:after {
    content: '';
    width: 8px;
    height: 8px;
    background: #222222;
    position: absolute;
    top: 6px;
    right: 6px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  [type='checkbox']:not(:checked)+label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  [type='checkbox']:checked+label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  &.editor-container {
    border-width: 1px;
  }
}

.modal-ticket-detail-maincontent {
  max-height: 630px;
  overflow: auto;
}

.form-field-loader {
  position: absolute;
  right: 15px;
  top: 15%;

  .spinner-border {
    margin: 0 !important;
  }
}

.ticket-header-sticky {
  position: sticky;
  top: 0;
  z-index: 5;
}

.ticket-card-id {
  white-space: nowrap;
}

.ticket-card-title {
  word-break: break-word;
}

.unit-strike-price {
  text-decoration: line-through;
}

.react-select__menu {
  margin: 0;
}

.react-select__option {
  padding: 6px 10px !important;
  border-radius: 6px !important;
  font-weight: 500 !important;
}

// drawer component custom
.ad-drawer-section {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  display: none;

  &.ad-drawer-active {
    display: flex;
    justify-content: flex-end;
  }

  .ad-drawer-section-inner {
    width: 400px;
    height: 100vh;
    background: #ffffff;
    position: relative;
    z-index: 2;
  }

  .app-drawer-nav-body {
    overflow: auto;
  }

  .ad-drawer-section-overlay {
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }
}

// accordion component
.ad-accordion-item {
  &.ad-accordion-active {
    .ad-accordion-icon {
      i {
        transform: rotate(180deg);
      }
    }
  }
}

.nav-line-tabs {
  .nav-link {
    &.active {
      svg {
        stroke: var(--kt-primary);

        path {
          stroke: var(--kt-primary);
        }
      }
    }
  }
}

.matrix-report-table {
  .head-col-sticky {
    position: sticky;
    background: #ffffff;
    z-index: 2;

    &:nth-child(1) {
      left: 0;
    }

    &:nth-child(2) {
      left: 400px;
    }
  }

  .body-col-sticky {
    position: sticky;
    background: #ffffff;
    z-index: 2;

    &:nth-child(1) {
      left: 0;
    }

    &:nth-child(2) {
      left: 400px;
    }
  }
}

.nav-line-tabs-small-gap {
  .nav-item {
    .nav-link {
      margin-left: 4px;
    }
  }
}

.react-select__menu .react-select__option:hover:not(.react-select__option--is-selected):not(.react-select__option--is-disabled) {
  background: #f1f3f5 !important;
  color: #333333 !important;
}

.react-select__menu .react-select__option--is-selected,
.react-select__menu .react-select__option--is-selected:hover {
  background: #e2e6ea !important;
  color: #333333 !important;
}

.react-select__menu .react-select__option--is-selected:hover {
  background: #e2e6ea !important;
  color: #333333 !important;
}

.react-select-multi-tags {
  .css-1p3m7a8-multiValue {
    margin: 0;
    padding: 0;
    background: none;
    border-radius: 0;
    display: inline-block;
    vertical-align: middle;
  }

  .react-select__control {
    height: 36px;
  }

  .react-select__placeholder {
    margin: 0;
    line-height: 34px;
  }

  .react-select__input-container {
    margin: 0;
    padding: 0;
    line-height: 34px;
  }

  .react-select__value-container {
    padding: 0 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    display: flex;
    flex-wrap: nowrap;
    height: 34px;
  }

  .react-select__multi-value {
    .react-select__multi-value__remove {
      display: none;
    }

    .react-select__multi-value__label {
      padding: 0;
      font-size: inherit;
      line-height: 34px;
    }
  }
}

.app-default-date-tooltip {
  display: none;
  opacity: 0;

  &.popover {
    padding: 10px;
    text-align: center;
    position: absolute;
    left: 10px;
    top: 100%;
    white-space: nowrap;

    &.tooltip-right {
      left: auto;
      right: 10px;
    }
  }
}

.app-default-date-area {
  display: inline-block;
}

.app-default-date-area {
  &:hover {
    .app-default-date-tooltip {
      display: inline-block;
      opacity: 1;
    }
  }
}



.app-default-tooltip {
  display: none;
  opacity: 0;

  &.popover {
    padding: 10px;
    text-align: center;
    position: absolute;
    left: 10px;
    top: 100%;
    white-space: nowrap;
  }
}

.app-default-tooltip-area {
  display: inline-block;
}

.app-default-tooltip-area {
  &:hover {
    .app-default-tooltip {
      display: inline-block;
      opacity: 1;
    }
  }
}

.ad-notification-message {
  width: calc(100% - 30px);
}

.notification-date-group {
  .ad-notification-message {
    width: auto;
  }

  .timeline-item {
    width: auto !important;
  }
}

.page-title {
  width: calc(100% - 70px);
}

// product report
.price-list-child-table {
  margin-bottom: 0 !important;

  thead {
    background: #f5f8fa !important;
  }
}


.app-default-date-tooltip {
  &.blocked-order-tooltip {
    white-space: normal;
  }
}

.tooltip-product-list {
  max-height: 250px;
  overflow: auto;
}

// live chat

.live-chat-leftblock {
  .app-default-date-tooltip {
    &.popover {
      right: 10px;
      left: auto;
    }
  }
}

.chat-sent-block{
  .app-default-date-tooltip{
    &.popover{
      right: 10px;
      left: auto;
    }
  }
.profibility-chart-wrapper{
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}}
